<template>
  <div class="edu">
    <Banner/>
    <div class="firstPlate">
      <div class="solutionPublicTitle">
        <p>功能介绍</p>
        <p>FUNCTION INTRODUCTION</p>
      </div>
      <div class="appbox white_bg">
        <div class="appboxCenter">
          <div class=""><img src="@/assets/imgs/solution/live.png" alt=""></div>
          <div class="hy_yyjs_infos">
            <div class="yyjs_title">
              <span>直播教学</span>
              <b></b>
            </div>
            <h6>为线上直播课赋予AI的能力，一比一还原线下课堂</h6>
            <div class="yyjs_details yyjs_details1">
              <p>实时生成PPT导航页</p>
              <h6>支持实时地捕捉教学视频关键帧，生成导航条，方便课程预览和学习</h6>
            </div>
            <div class="yyjs_details">
              <p>实时双语字幕</p>
              <h6>支持实时生成中/英字幕，为国际交流学生和特殊群体学生提供便利</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="appbox gray_bg">
        <div class="appboxCenter">
          <div class="hy_yyjs_infos">
            <div class="yyjs_title">
              <span>录播教学</span>
              <b></b>
            </div>
            <h6>为教学视频提供便利的双语字幕、内容搜索、内容分析</h6>
            <div class="yyjs_details yyjs_details1">
              <p>字幕与视频同步</p>
              <h6>支持字幕与视频同步，并可以实现视频中知识点的精准搜索与定位</h6>
            </div>
            <div class="yyjs_details">
              <p>内容智能分析</p>
              <h6>根据音视频和字幕，可以对教学视频内容进行思政、教学质量等进行智能评价和分析</h6>
            </div>
          </div>
          <div class=""><img src="@/assets/imgs/solution/recording.png" alt=""></div>
        </div>
      </div>
      <div class="appbox white_bg">
        <div class="appboxCenter">
          <div class=""><img src="@/assets/imgs/solution/modify.png" alt=""></div>
          <div class="hy_yyjs_infos">
            <div class="yyjs_title">
              <span>视频修改</span>
              <b></b>
            </div>
            <h6>提供字幕修正，视频剪辑功能，提高点播视频质量</h6>
            <div class="yyjs_details yyjs_details1">
              <p>字幕修改</p>
              <h6>光标移入即可修改，光标移出修改完成</h6>
            </div>
            <div class="yyjs_details">
              <p>视频剪辑</p>
              <h6>提供视频剪切、删除功能，一站式便捷操作</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="secondPlate">
      <div class="solutionPublicTitle">
        <p>方案架构</p>
        <p>SOLUTION ARCHITECTURE</p>
      </div>
      <div class="hy_index_fajg">
        <div class="hy_fajg_body smart_edu_fajg_body">
          <div class="fajg_body_left">
            <div class="fajg_architecture">
              <div class="fajg_archi_body smart_edu_yyc">
                <h4>应<br />用<br />层</h4>
                <div class="fajg_body_details">
                  <div class="detail_body">
                    <p class="detail_body_title">直播教学</p>
                    <p class="detail_body_info">实时字幕</p>
                    <p class="detail_body_info">实时PPT</p>
                    <p class="detail_body_info">直播回放</p>
                  </div>
                  <div class="detail_body">
                    <p class="detail_body_title">录播教学</p>
                    <p class="detail_body_info">双语字幕</p>
                    <p class="detail_body_info">实时PPT</p>
                    <p class="detail_body_info">智能推荐</p>
                  </div>
                  <div class="detail_body">
                    <p class="detail_body_title">数据分析</p>
                    <p class="detail_body_info">智能评价</p>
                    <p class="detail_body_info">内容检索</p>
                    <p class="detail_body_info">个性推荐</p>
                  </div>
                </div>
              </div>
              <div class="fajg_archi_body">
                <h4>服<br />务<br />层</h4>
                <div class="fajg_body_details">
                  <span>语音识别</span>
                  <span>机器翻译</span>
                  <span>智能分析</span>
                  <span>流媒体处理</span>
                </div>
              </div>
              <div class="fajg_archi_body">
                <h4>基<br />础<br />硬<br />件</h4>
                <div class="fajg_body_details">
                  <span>AI服务器</span>
                  <span>流媒体处理服务器</span>
                  <span>字幕主机</span>
                  <span>麦克风</span>
                </div>
              </div>
            </div>
          </div>
          <div class="fajg_body_right smart_edu_fajg">
            <div class="archi_instruction">
              <h5>架构说明：</h5>
              <p>在字幕主机中集成语音识别和机器翻译引擎，即可为传统的教学添加AI能力。用户通过麦克风设备向字幕主机发出语音信号，经由AI引擎处理后，即可显示课堂实时字幕。同时，产品架构可支持单人直播字幕显示、互动直播字幕显示、录播视频字幕转写及内容智能检索等，提升师生的教与学质量。</p>
            </div>
            <div class="recommend_config">
              <h5>服务器配置推荐：</h5>
              <ul>
                <li>CPU：至强4210</li>
                <li>运行内存：16G</li>
                <li>机械硬盘：1T</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="thirdPlate">
      <div class="solutionPublicTitle">
        <p>合作伙伴</p>
        <p>COOPERATIVE PARTNER</p>
      </div>
      <div class="hy_hzhb_body">
        <div><img src="@/assets/imgs/par_14.jpg" alt="天津大学"></div> <!-- 天津大学 -->
        <div><img src="@/assets/imgs/tjlg_university.png" alt="天津理工大学"></div>  <!-- 天津理工大学 -->
        <div><img style="width: 76%;height: auto;" src="@/assets/imgs/zxkj.png" alt="紫旭科技"></div>  <!-- 紫旭科技 -->
        <div style="margin-right: 0;"><img style="width: 87%;height: auto;" src="@/assets/imgs/THTF.png" alt="同方艾威康"></div>  <!-- 同方艾威康 -->
      </div>
    </div>
    <div class="hy_index_gray">
      <div class="hy_index_hzzx">
        <p><span>点击下方按钮，获得我们的专属支持</span></p>
        <div><button><a href="mailto:bd@huiyan-tech.com" target="_blank">合作咨询</a></button></div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner"
export default {
  name: "Edu",
  components: {
    Banner,
  },
}
</script>

<style lang="less" scoped>
.appbox {
  width: 100%;
  height: 500px;
  .appboxCenter {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    img {
      width: 440px;
      height: 330px;
    }
    .hy_yyjs_infos .yyjs_title{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      height: 50px;
    }
    .hy_yyjs_infos .yyjs_title span{
      color: #000;
      font-size: 30px;
      font-weight: 500;
    }
    .hy_yyjs_infos .yyjs_title b{
      width: 50px;
      height: 3px;
      background-color: #155FA1;
      border-radius: 2px;
    }
    .hy_yyjs_infos .yyjs_details{
      margin-top: 40px;
    }
    .hy_yyjs_infos .yyjs_details1{
      margin-top: 20px;
    }
    .hy_yyjs_infos .yyjs_details p{
      font-size: 24px;
      font-weight: 400;
      color: #000000;
      line-height: 33px;
      text-align: center;
    }
    .hy_yyjs_infos>h6{
      margin-top: 18px;
    }
    .hy_yyjs_infos h6{
      font-size: 18px;
      font-weight: 300;
      color: #383737;
      text-align: center;
      line-height: 38px;
    }
  }
}
.gray_bg {
  background: #F4F5FA;
}
.secondPlate {
  .hy_index_fajg{
    display: flex;
    justify-content: center;
    .hy_fajg_body{
      width: 1200px;
      height: 598px;
      display: flex;
      background: #FFFFFF;
      border-radius: 38px 0 0 38px;
      border: 1px solid #DADADA;
      position: relative;
      .fajg_body_left{
        width: 678px;
        padding-left: 40px;
        padding-top: 38px;
        margin-right: 42px;
        .fajg_pictures{
          height: 188px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img{
            width: 215px;
            height: 188px;
          }
        }
        .fajg_architecture{
          margin-top: 10px;
          .fajg_archi_body{
            height: 92px;
            background: #FFFFFF;
            box-shadow: 0 3px 3px 0 rgba(233, 233, 233, 0.5);
            border-radius: 6px;
            border: 1px solid #EBEBEB;
            margin-bottom: 22px;
            display: flex;
            h4{
              flex-shrink: 0;
              width: 58px;
              height: 92px;
              background: #155FA1;
              border-radius: 6px 0 0 6px;
              border: 1px solid #155FA1;
              color: #fff;
              font-weight: 600;
              font-size: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 20px;
            }
            .fajg_body_details{
              flex: 1;
              padding: 24px 22px;
              display: flex;
              span{
                width: 130px;
                height: 45px;
                border-radius: 6px;
                border: 1px solid #155FA1;
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                line-height: 45px;
                text-align: center;
                margin-right: 15px;
              }
              span:nth-of-type(4){
                margin-right: 0;
              }
            }
          }
          .fajg_archi_body:nth-of-type(3){
            margin-bottom: 0;
          }
          /* 智慧教育方案架构 */
          .smart_edu_yyc .detail_body{
            width: 180px;
            height: 149px;
            background: #FFFFFF;
            box-shadow: 0 3px 3px 0 rgba(233, 233, 233, 0.5);
            border-radius: 6px;
            border: 1px solid #EBEBEB;
            margin-right: 24px;
          }
          .smart_edu_yyc .detail_body:nth-of-type(3){
            margin-right: 0;
          }
          .smart_edu_yyc{
            height: 149px;
            border: none;
            box-shadow: none;
          }
          .smart_edu_yyc h4{
            height: 149px;
          }
          .smart_edu_yyc p{
            text-align: center;
          }
          .smart_edu_yyc .detail_body_title{
            height: 36px;
            line-height: 36px;
            background: #155FA1;
            border-radius: 6px 6px 0 0;
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .smart_edu_yyc .detail_body_info{
            font-size: 15px;
            font-weight: 500;
            color: #464646;
            line-height: 30px;
          }
          .smart_edu_yyc .fajg_body_details{
            padding: 0 0 0 22px;
          }
        }
      }
      .fajg_body_right{
        position: absolute;
        right: 0;
        top: -2px;
        bottom: -2px;
        width: 355px;
        padding: 30px;
        border: 1px solid #155FA1;
        background: #155FA1 url("~@/assets/imgs/solution/archi_bg.png") no-repeat 99px 327px;
        background-size: 234px 234px;
        .archi_instruction{
          height: 252px;
          padding-bottom: 24px;
          border-bottom: 1px dotted #fff;
          margin-bottom: 28px;
        }
        h5{
          font-size: 18px;
          color: #fff;
          font-weight: 400;
        }
        p, li{
          font-size: 16px;
          color: #fff;
          font-weight: 400;
          line-height: 28px;
        }
        li:before{
          content: '';
          width: 4px;
          height: 4px;
          display: inline-block;
          border-radius: 50%;
          background: #fff;
          margin-right: 5px;
          transform: translateY(-50%);
        }
      }
      .smart_edu_fajg{
        background: #155FA1 url("~@/assets/imgs/solution/archi_bg.png") no-repeat 99px 300px;
        background-size: 234px 192px;
        .archi_instruction{
          height: 220px;
        }
      }
    }
    .smart_edu_fajg_body{
      height: 510px;
    }
  }
}
</style>